import React from 'react'

import CheerCardContext from '../../contexts/CheerCard.js'
import Media from '../Media.js'
import Header from '../Header.js'
import Submit from '../Submit.js'

const initialState = {
  accepted: false
}

class Review extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }

    if (!this.props.context.mediaSelection || !this.props.context.mediaSelection.file) {
      this.props.history.push('/')
    }
  }

  componentDidMount() {
    this.props.context.unfocus()
    this.props.context.reviewPage = true
  }

  componentWillUnmount() {
    this.props.context.reviewPage = false
  }

  render() {
    return (
      <>
        <Header
          title='Review'
          links={{
            previous: '/text'
          }}
          changeLocation={ this.changeLocation }
          context={this.props.context}
          history={this.props.history}
        />
        <Media
          innerRef={this.media}
          onItemFocus={() => null}
          onItemUnfocus={() => null}
          disabled
        />
        <Submit submit={() => this.props.history.push('/share')} openRules={() => this.props.context.setShowRules(true)} />
      </>
    )
  }
}

// Wrap the page component in the context
// so we can maintain state about the
// current cheer card.
export default ({ history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Review reviewPage context={context} history={history} continue={() => {
        history.push('/share')
      }}/>
    )}
  </CheerCardContext.Consumer>
)
