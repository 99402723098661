import React from 'react'

import galleryHero from '../../images/asset.hero.gallery.png'

import Hero from '../Hero.js'
import Text from '../Text.js'
import Footer from '../Footer.js'

const NotFound = () => (
  <>
    <Hero
      image={galleryHero}
      title='Page Not Found' />
    <Text>
      <p>
        Unfortunately we could not find the page you were looking for.
      </p>
    </Text>
    <br />
    <Footer type='legal' />
  </>
)

export default NotFound
