import React from 'react'

import { fetchGalleryItem } from '../helpers/api.js'
import Media from './Media.js'
import { ReactComponent as Loader } from '../images/loader.svg'

import  { isBrowser } from "react-device-detect"
import DesktopFrameContext from '../contexts/DesktopFrame.js'

class GalleryItemOverlay extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      item: false,
      loaded: false
    }

    this.media = React.createRef()
  }

  componentDidMount() {
    if (this.props.context) {
      this.props.context.setFixedScroll(true)
    }

    if (this.props.itemId) {
      fetchGalleryItem(this.props.itemId).then((response) => {
        const item = response.data
        this.setState({
          item: item,
          loaded: true
        })
      }).catch(() => {
        alert('We could not find the Cheer Card you were looking for')
      })
    }
  }

  componentWillUnmount() {
    if (this.props.context) {
      this.props.context.setFixedScroll(false)
    }
  }

  handleClose() {
    if (this.props.onCloseOverlay) {
      this.props.onCloseOverlay()
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div className='galleryItemOverlay'>
          <button className='galleryItemOverlayClose' onClick={() => this.handleClose()}>X</button>
          <div className='galleryItemOverlayContainer' style={{width: '200px', height: '200px', padding: '20px'}}>
            <Loader />
          </div>
        </div>
      )
    }

    if (!this.state.item) {
      alert('We could not find the Cheer Card you were looking for')
      return null
    }

    const data = JSON.parse(this.state.item.data)

    const frameWidth = isBrowser ? 200 : 300

    let ratio = frameWidth / data.dimensions.width
    let height = data.dimensions.height * ratio

    const containerStyles = {
      height: `${height}px`,
      width: `${frameWidth}px`
    }

    return (
      <div className='galleryItemOverlay'>
        <button className='galleryItemOverlayClose' onClick={() => this.handleClose()}>X</button>
        <div className='galleryItemOverlayContainer' style={containerStyles}>
          <Media
            reviewPage={true}
            ratio={ratio}
            innerRef={this.media}
            mediaSelection={data.background}
            draggables={Object.values(data.objects)}
          />
        </div>
        <span className='galleryItemOverlayText'>{this.state.item.user ? this.state.item.user.name : 'Unknown'}</span>
      </div>
    )
  }
}

GalleryItemOverlay.defaultProps = {
  itemId: null,
  context: null
}


export default ({ itemId, onCloseOverlay }) => (
  <DesktopFrameContext.Consumer>
    {(context) => (
      <GalleryItemOverlay itemId={itemId} context={context} onCloseOverlay={onCloseOverlay} />
    )}
  </DesktopFrameContext.Consumer>
)
