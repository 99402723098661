import React from 'react'

import styled, { keyframes } from 'styled-components'

const addKeyframes = (props) =>
  keyframes`
    from {
      background-position-y: 0px
    }
    to {
      background-position-y: -${props.sticker.steps * props.sticker.frame_size}px
    }
  `
const Sprite = styled.div`
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  max-width: ${ props => props.sticker.frame_size}px;
  max-height: ${ props => props.sticker.frame_size}px;
  background-image: url("${ props =>
    props.sticker.url ? props.sticker.url : props.sticker.value
}");
  // transition: transform 200ms;
  transform: translateZ(0);
  userSelect: none;
  animation: ${addKeyframes}
              ${props => (props.sticker.steps / props.sticker.fps)}s
                steps(${props => props.sticker.steps})
                  infinite;
`

class Sprites extends React.Component {
  componentDidMount() {
    let img = new Image()
    img.onload = this.props.onLoad
    img.src = this.props.sticker.url
  }

  componentDidUpdate() {
    console.log('Sprites.componentDidUpdate')
  }

  render() {
    const { sticker, className } = this.props
    console.log('Sprites.render')

    return (
      <Sprite className={ className } sticker={ sticker } />
    )
  }
}

Sprites.defaultProps = {
  disabled: false,
  style: {},
  sticker: {},
  className: '',
  text: '',
  onChange: (e) => {},
  onLoad: (e) => {}
}

export default Sprites
