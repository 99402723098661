import React from 'react'

import Header from '../Header.js'
import { Legal as LegalFooter, LegalFooter as Footer } from '../Footer.js'

import CheerCardContext from '../../contexts/CheerCard.js'

import GalleryBlock from '../Gallery.js'

const initialState = {
  galleryId: null
}

class Gallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  componentDidMount() {
    let galleryId = null

    if (this.props.match && this.props.match.params.galleryId) {
      galleryId = this.props.match.params.galleryId
    }

    setTimeout(() => {
      this.setState((state) => {
        return {
          ...state,
          galleryId: galleryId
        }
      })
    }, 1)

    this.props.context.unfocus()
    this.props.context.reviewPage = true
  }

  componentWillUnmount() {
    this.props.context.reviewPage = false
  }

  openItem(item) {
    if (item.id) {
      this.props.history.push('/gallery/' + item.id)
      this.setState({
        galleryId: item.id
      })
    }
  }

  closeOverlay() {
    this.props.history.push('/gallery')
    this.setState({
      galleryId: false
    })
  }

  render() {
    return (
      <>
        <Header
          title='Gallery'
          context={this.props.context}
          history={this.props.history}
        />
        <GalleryBlock galleryId={this.state.galleryId} onItemClick={item => {
          this.openItem(item)
        }} onCloseOverlay={() => {
          this.closeOverlay()
        }} />
        <Footer>
          <LegalFooter />
        </Footer>
      </>
    )
  }
}

Gallery.defaultProps = {
  showingOverlay: false,
  context: false,
  match: false,
  history: false
}

export default ({ match, history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Gallery reviewPage context={context} match={match} history={history} />
    )}
  </CheerCardContext.Consumer>
)
