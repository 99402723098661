/* eslint-disable max-params */
export class DraggableObject {
  type = null
  value = null
  name = null
  color = '#b52541'
  position = {
    x: 0,
    y: 0
  }
  scale = 1.0
  rotation = 0.0
  is_sprite = false
  steps = 0
  frame_size = 0
  fps = 0

  constructor(type, value, position, scale, rotation, is_sprite, steps, frame_size, fps) {
    this.type = type
    this.value = value
    this.position = position
    this.scale = scale
    this.rotation = rotation
    this.is_sprite = is_sprite
    this.steps = steps
    this.frame_size = frame_size
    this.fps = fps
  }
}
