/* eslint-disable no-alert */
import React, { useState } from 'react'
import '../scss/Blocks/blocks.submit.scss'

import Tick from '../images/icon.rbtick.svg'

export default function Submit(props) {
  const [ticked, setTicked] = useState(false)

  return (
    <div className='submit'>
      <div className='gdpr gdpr--has-checkbox'>
        <div className='gdprCheckbox'>
          <input type='checkbox'
            name='accept_info'
            id='accept_info'
            onClick={() => setTicked(!ticked)} />
          <label htmlFor='accept_info'
            className='gdprCheckboxLabel'>
            <img src={Tick} alt=''/>
          </label>
        </div>
        <div className='gdprContent'>
          <label htmlFor='accept_info'>
            By checking the box, you hereby agree to receive additional information from New York Red Bulls, Major League Soccer, Soccer United Marketing and their marketing partners in accordance with RBNY's <a href='https://www.mlssoccer.com/privacy-policy'>Privacy Policy</a> and <a href='https://www.mlssoccer.com/terms-service'>Terms of Use</a>
          </label>
        </div>
      </div>

      <div className='gdpr'>
        By Clicking Submit, I agree to&nbsp;
        <a href='https://policies.redbull.com/policies/RedBull.com_US/201907010152/en/terms.html'>Terms of Use</a>,
        and <a href='https://policies.redbull.com/policies/RedBull.com_US/201907010152/en/privacy.html'>Privacy Policy</a>
      </div>

      <button
        className='btn btn--submit'
        onClick={ticked ? props.submit :
          () => alert('Please accept the terms and conditions to submit your design.')}
      >
       Submit your Design*
      </button>
      <div className='subtext subtext-gdpr'>
        *Registration Required<br />
        NO PURC. NEC. VOID WHERE PROHIBITED. 7/1/19–9/30/19. Open to legal residents of NY, CT, and NJ living w/in 75 mi. of Red Bull Arena, 18+.&nbsp; <a href='#' onClick={(e) => {
          e.preventDefault()
          props.openRules()
        }} >
          Full Rules
        </a>
      </div>
    </div>
  )
}
