import React from 'react'

export default (props) => {
  if (props.type.match('video/')) {
    return (
      <video className='mediaVideo' src={props.dataUrl} autoPlay loop muted playsInline />
    )
  }

  return (
    <img src={props.dataUrl} className='mediaImage' alt='Selected Media'/>
  )
}
