import React from 'react'
import '../scss/Blocks/blocks.share.scss'

import Media from './Media.js'

import background from '../images/asset.arena.jpg'
import twitter from '../images/icon.twitter.svg'
import facebook from '../images/icon.facebook.svg'
import { ReactComponent as Loading } from '../images/loader.svg'

const styles = {
  shareBackground: {
    backgroundImage: `url(${background})`
  }
}

export default class Share extends React.PureComponent {
  constructor(props) {
    super(props)

    this.media = React.createRef()
  }

  render() {
    const PREVIEW_MARGIN = 60

    let width = (this.props.context.dimensions.width - PREVIEW_MARGIN)
    let ratio = width / this.props.context.dimensions.width
    let height = this.props.context.dimensions.height * ratio

    const previewStyles = {
      height: `${height}px`,
      width: `${width}px`
    }

    const shareUrl = process.env.REACT_APP_SHARE_URL

    return (
      <div className='share' style={styles.shareBackground}>
        <div className='preview' style={previewStyles}>
          { this.props.preview ? (
            <Media
              reviewPage={true}
              ratio={ratio}
              innerRef={this.media}
            />
          ) : (
            <p><Loading /></p>
          )}
        </div>
        <div className='shareActions'>
          <h2>Back of the net!</h2>
          <h3>Share your support with the world.</h3>

          <nav className='socialShare'>
            <a
              href={'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl}
              className='socialShareItem'
              target='_blank'
              rel='noopener noreferrer'>
              <img src={facebook} alt='Facebook' />
            </a>
            <a
              href={'https://twitter.com/intent/tweet?status=' + shareUrl}
              className='socialShareItem'
              target='_blank'
              rel='noopener noreferrer'>
              <img src={twitter} alt='Twitter' />
            </a>
          </nav>

          { this.props.downloadUrl && (
            <>
              <button className='btn btn--save' onClick={() => window.open(this.props.downloadUrl, 'Download')}>SAVE</button>
              <small>Save your Cheer Card to your device</small>
            </>
          )}

          <button
            onClick={() => {
              this.props.context.reset()
              this.props.history.push('/')
            }}
            className='startNew'>
            Start a new Cheer Card
          </button>
        </div>
      </div>
    )
  }
}
