import React from 'react'

import CheerCardContext from '../../contexts/CheerCard.js'

import Header from '../Header.js'
import { fetchTemplates } from '../../helpers/api.js'
import { ReactComponent as Loader } from '../../images/loader.svg'
import LoadingImage from '../LoadingImage.js'

const initialState = {
  templates: [],
  isLoading: true
}
const radioStyle = { opacity: 0 }

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  componentDidMount() {
    fetchTemplates().then((templates) => {
      this.setState({
        templates: templates,
        isLoading: false
      })
    })
  }


  handleClick(template) {
    this.props.context.updateMediaSelection({
      file: { type: 'image/*' },
      dataUrl: template.url
    })
    this.props.continue()
  }

  render() {
    const { isLoading } = this.state
    return (
      <>
        <Header
          title='Templates'
          links={{ previous: '/', next: false }}
          showBack
          context={this.props.context}
          history={this.props.history}
        />
        {isLoading &&
          <div className='stickerSelect--loadingAll'>
            <Loader />
          </div>
        }
        {!isLoading &&
        <div className='stickerSelect'>
          { this.state.templates.map((template, index) => (

            <div className='stickerSelectItem'
              key={index}
              onClick={() => this.handleClick(template)}>
              <input type='radio' name='sticker' id={'sticker-' + index} style={radioStyle} />
              <label htmlFor={'sticker-' + index} className='stickerItemLabel'>
                {isLoading &&
                  <div className='stickerSelect stickerSelect--loadingSingle'>
                    <Loader />
                  </div>
                }
                <LoadingImage sticker={{ url: template.preview }} />
              </label>
            </div>

          ))}
        </div>}

      </>
    )
  }
}

// Wrap the page component in the context
// so we can maintain state about the
// current cheer card.
export default ({ history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Template context={context} history={history} continue={() => {
        history.push('/stickers')
      }}/>
    )}
  </CheerCardContext.Consumer>
)
