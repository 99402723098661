/* eslint-disable no-throw-literal */
/* eslint-disable no-alert */
import React from 'react'
import { Link } from 'react-router-dom'
import media from '../images/icon.media.svg'
import '../scss/Blocks/blocks.mediaSelect.scss'

import { uploadFile } from '../helpers/api.js'
import { ReactComponent as Loader } from '../images/loader.svg'

export default class MediaSelect extends React.Component {
  state = {
    isUploading: false
  }

  handleOnChange(e) {
    if (e.target.files.length < 1) {
      return
    }

    if (this.state.isUploading) {
      return
    }

    let file = e.target.files[0]

    this.setState({
      isUploading: true
    })

    uploadFile(file).then(response => {
      this.props.onSelect({
        file: file,
        dataUrl: response.data.url
      })
    }, reason => {
      if (reason.status !== 200) {
        this.setState({
          isUploading: false
        })

        alert('Image upload failed, please try again.')
        throw 'Unable to upload file'
      }
    })
  }

  render() {
    return (
      <section className='mediaSelect'>
        {this.state.isUploading &&
          <div className='mediaSelectAction mediaSelectAction--loading'>
            <Loader />
          </div>
        }
        {!this.state.isUploading &&
          <>
            <label htmlFor='media-select'>
              <div className='mediaSelectAction'>
                <img src={media} alt='Launch Media'/>
              </div>
            </label>
            <input
              id='media-select'
              type='file'
              style={{ left: '-99999px', position: 'absolute' }}
              accept='image/*,video/*'
              name='media-select'
              onChange={(e) => this.handleOnChange(e)}
            />
            <h2>Start with a photo or video.</h2>
            <small>Or use a template</small>
            <Link to='/template' className='btn btn--big-rounded'>Backgrounds</Link>
          </>
        }
      </section>
    )
  }
}
