import React from 'react'

const initialState = {
  mediaSelection: null,
  draggables: [],
  dimensions: {
    width: 400,
    height: 300,
    scale: 1.0
  },
  showingRules: false,
  showingPrizes: false,
  addDraggable: () => {},
  removeDraggable: () => {},
  updateDraggable: () => {},
  updateSelected: () => {},
  focus: () => {},
  unfocus: () => {},
  updateMediaSelection: () => {},
  reset: () => {},
  hasText: () => {},
  import: () => {},
  export: () => {},
  setMediaResolution: () => {}
}

// TODO:
// Add a method to calculate the resolution of the editable viewport

const CheerCardContext = React.createContext({ ...initialState })

export class CheerCardProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...initialState,
      setShowRules: this.setShowRules.bind(this),
      setShowPrizes: this.setShowPrizes.bind(this),
      getDraggables: this.getDraggables.bind(this),
      getDraggablesById: this.getDraggablesById.bind(this),
      addDraggable: this.addDraggable.bind(this),
      removeDraggable: this.removeDraggable.bind(this),
      updateDraggable: this.updateDraggable.bind(this),
      updateSelected: this.updateSelected.bind(this),
      getSelected: this.getSelected.bind(this),
      focus: this.focus.bind(this),
      unfocus: this.unfocus.bind(this),
      updateMediaSelection: this.updateMediaSelection.bind(this),
      reset: this.reset.bind(this),
      hasText: this.hasText.bind(this),
      import: this.import.bind(this),
      export: this.export.bind(this),
      setMediaResolution: this.setMediaResolution.bind(this),
      reviewPage: false
    }
  }

  setShowRules(value) {
    this.setState({
      showingRules: value
    })
  }

  setShowPrizes(value) {
    this.setState({
      showingPrizes: value
    })
  }

  reset() {
    this.setState(
      {
        ...initialState,
        setShowRules: this.setShowRules.bind(this),
        setShowPrizes: this.setShowPrizes.bind(this),
        getDraggables: this.getDraggables.bind(this),
        getDraggablesById: this.getDraggablesById.bind(this),
        addDraggable: this.addDraggable.bind(this),
        removeDraggable: this.removeDraggable.bind(this),
        updateDraggable: this.updateDraggable.bind(this),
        updateSelected: this.updateSelected.bind(this),
        getSelected: this.getSelected.bind(this),
        focus: this.focus.bind(this),
        unfocus: this.unfocus.bind(this),
        updateMediaSelection: this.updateMediaSelection.bind(this),
        reset: this.reset.bind(this),
        hasText: this.hasText.bind(this),
        import: this.import.bind(this),
        export: this.export.bind(this),
        setMediaResolution: this.setMediaResolution.bind(this),
        reviewPage: false
      }
    )
  }

  getDraggables() {
    return [...this.state.draggables]
  }

  getDraggablesById(id) {
    let draggables = [...this.state.draggables]

    return draggables[id]
  }

  addDraggable(draggable) {
    let draggables = [ ...this.state.draggables ]
    draggables.push(draggable)

    this.setState({
      draggables
    })

    return draggables.length - 1
  }

  removeDraggable(id) {
    let draggables = [ ...this.state.draggables ]
    draggables = draggables.slice(0, id - 1)
      .concat(draggables.slice(id, draggables.length))

    this.setState({
      draggables
    })
  }

  updateDraggable(id, data) {
    let draggables = [ ...this.state.draggables ]
    let current = draggables[id]
    draggables[id] = { ...current, ...data }

    this.setState({
      draggables
    })
  }

  updateSelected(data) {
    let selectedKey = this.state.selected
    this.updateDraggable(selectedKey, data)
  }

  getSelected() {
    let selectedKey = this.state.selected
    return this.getDraggablesById(selectedKey)
  }

  focus(id) {
    this.setState({
      selected: id
    })
  }

  unfocus() {
    this.setState({
      selected: null
    })
  }

  updateMediaSelection(selection) {
    this.setState({
      mediaSelection: { ...selection }
    })
  }

  hasText() {
    return this.state.draggables.filter(draggable => {
      return draggable.type === 'text'
    }).length > 0
  }

  import(data) {
    // TODO:
    // - Map the data into the format we need
    this.setState({
      draggables: { ...data.draggables },
      mediaSelection: data.mediaSelection
    })
  }

  export() {
    // TODO:
    // - Format and remove things we don't need
    return {
      draggables: { ...this.state.draggables },
      mediaSelection: this.state.mediaSelection
    }
  }

  setMediaResolution(media) {
    const domElement = media.current

    this.setState({
      dimensions: {
        height: domElement.offsetHeight,
        width: domElement.offsetWidth,
        scale: 1.0
      }
    })
  }

  render() {
    return (
      <CheerCardContext.Provider value={this.state}>
        {this.props.children}
      </CheerCardContext.Provider>
    )
  }
}

export default CheerCardContext
