import React from 'react'
import { isBrowser } from 'react-device-detect'
import Media from './Media.js'

import '../scss/App.scss'
import '../scss/Blocks/blocks.winner.scss'

export default class Winner extends React.Component {
  constructor(props) {
    super(props)

    this.media = React.createRef()
  }

  render() {
    const data = JSON.parse(this.props.winningSubmission.data)

    const frameWidth = isBrowser ? 200 : 300

    let ratio = frameWidth / data.dimensions.width
    let height = data.dimensions.height * ratio

    const containerStyles = {
      height: `${height}px`,
      width: `${frameWidth}px`
    }

    return (
      <div className='winner'>
        <h2>Congratulations, <br />{this.props.winningSubmission.user.name}</h2>

        <div className='galleryItemOverlayContainer' style={containerStyles}>
          <Media
            reviewPage={true}
            ratio={ratio}
            innerRef={this.media}
            mediaSelection={data.background}
            draggables={Object.values(data.objects)}
          />
        </div>
      </div>
    )
  }
}


