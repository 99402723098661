import React from 'react'

// const initialState = {
//   value: '#ffffff'
// }

class ColorSelector extends React.Component {
  handleChange(e) {
    let value = e.target.value

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  render() {
    return (
      <div className='color'>
        {this.props.colors.map((colorObject, index) => (
          <div className='colorItem' key={index}>
            <input type='radio'
              checked={this.props.selected === colorObject.hex}
              value={colorObject.hex}
              name='color'
              id={'color-' + colorObject.name}
              onChange={(e) => this.handleChange(e)}
            />
            <label
              htmlFor={'color-' + colorObject.name}
              className={'colorLabel colorLabel--' + colorObject.name}
            />
          </div>
        ))}
      </div>
    )
  }
}

ColorSelector.defaultProps = {
  selected: '#b52541',
  colors: [],
  onChange: () => {}
}

export default ColorSelector
