import React from 'react'

import CheerCardContext from '../../contexts/CheerCard.js'
import { DraggableObject } from '../../objects/Objects.js'
import Header from '../Header.js'

import {
  Text as TextFooter,
  Resize as ResizeFooter,
  Options as OptionsFooter,
  Rotate as RotateFooter,
  Color as ColorFooter,
  default as Footer
} from '../Footer.js'

import Media from '../Media.js'

const MODE_PLACE = 'place'
const MODE_ADD = 'add'
const MODE_TEXT_ENTRY = 'text-entry'
const MODE_COLOR = 'color'
const MODE_ROTATE = 'rotate'
const MODE_SCALE = 'scale'

const DEFAULT_MODE = MODE_ADD

class Text extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: DEFAULT_MODE
    }

    if (!this.props.context.mediaSelection || !this.props.context.mediaSelection.file) {
      this.props.history.push('/')
    }
  }

  setMode(mode) {
    this.setState({
      mode: mode
    })
  }

  handleFocus() {
    this.setMode(MODE_PLACE)
  }

  handleUnfocus() {
    this.setMode(DEFAULT_MODE)
  }

  getModeHeading() {
    if (this.state.mode === MODE_PLACE) {
      return 'Place'
    }

    if (this.state.mode === MODE_SCALE) {
      return 'Resize'
    }

    if (this.state.mode === MODE_ROTATE) {
      return 'Rotate'
    }

    return 'Enter'
  }

  renderFooter() {
    const selected = this.props.context.getSelected()

    if (this.state.mode === MODE_PLACE) {
      return (
        <OptionsFooter
          showColor={true}

          onColorClick={() => {
            this.setMode(MODE_COLOR)
          }}

          onRotateClick={() => {
            this.setMode(MODE_ROTATE)
          }}

          onResizeClick={() => {
            this.setMode(MODE_SCALE)
          }}
        />
      )
    }

    if (this.state.mode === MODE_ROTATE) {
      return (
        <RotateFooter
          value={selected.rotation || 0}

          onChange={(value) => {
            // console.log('RotateFooter.onChange', value)
            this.props.context.updateSelected({ rotation: value })
          }}

          onSubmit={() => {
            this.setMode(MODE_PLACE)
          }}
        />
      )
    }

    if (this.state.mode === MODE_SCALE) {
      return (
        <ResizeFooter
          value={selected.scale || 1.0}

          onChange={(value) => {
            // console.log('ResizeFooter.onChange', value)
            this.props.context.updateSelected({ scale: value })
          }}

          onSubmit={() => {
            this.setMode(MODE_PLACE)
          }}
        />
      )
    }

    if (this.state.mode === MODE_COLOR) {
      return (
        <ColorFooter
          value={selected.color || '#b52541'}

          onChange={(value) => {
            // console.log('ColorFooter.onChange', value)
            this.props.context.updateSelected({ color: value })
          }}

          onSubmit={() => {
            this.setMode(MODE_PLACE)
          }}
        />
      )
    }

    return (
      <TextFooter onClick={() => {
        this.setState({
          mode: MODE_TEXT_ENTRY
        })

        this.handleAddText(this.newText())
        this.setMode(MODE_PLACE)
      }} />
    )
  }

  newText() {
    return new DraggableObject('text', 'Change me', {
      x: 0,
      y: 0
    }, 1, 0)
  }

  handleAddText(text) {
    let index = this.props.context.addDraggable(text)

    this.props.context.focus(index)

    // TODO:
    // - Trigger force edit on the text field
  }

  componentDidMount() {
    this.props.context.unfocus()
    // TODO:
    // - Confirm if they want it to automatically add a text area
    // if (this.props.context.hasText() === false) {
    //   this.handleAddText(this.newText())
    //   this.setMode(MODE_PLACE)
    // }
  }

  render() {
    return (
      <>
        <Header
          title='Text'
          links={{ previous: '/stickers', next: '/review' }}
          context={this.props.context}
          history={this.props.history}
        />

        <Media
          onItemFocus={(key) => this.handleFocus(key)}
          onItemUnfocus={() => this.handleUnfocus()}
        />

        { this.state.mode !== MODE_TEXT_ENTRY && (
          <Footer>
            {this.renderFooter()}
          </Footer>
        )}
      </>
    )
  }
}

// Wrap the page component in the context
// so we can maintain state about the
// current cheer card.
export default ({ history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Text context={context} history={history} />
    )}
  </CheerCardContext.Consumer>
)
