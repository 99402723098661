import React from 'react'
import Slider from './Slider'
import ColorSelector from './ColorSelector.js'
import text from '../images/icon.text.svg'
import sticker from '../images/icon.sticker.svg'
import resize from '../images/icon.resize.svg'
import rotate from '../images/icon.rotate.svg'
import color from '../images/icon.color.svg'
import '../scss/Blocks/blocks.footer.scss'

export const LegalFooter = (props) => (
  <footer className='footer footer__no-stick'>
    {props.children}
  </footer>
)
export const Legal = () => (
  <div className='legal'>
    <nav className='legal__navigation'>
      <a className='legal__link' href='https://www.redbull.com/pp/en_US' target='_blank'
        rel='noopener noreferrer'>Privacy Policy</a>
      <a className='legal__link' href='https://www.redbull.com/tou/en_US' target='_blank'
        rel='noopener noreferrer'>Terms of Use</a>
      <a className='legal__link' href='http://energydrink-us.redbull.com/' target='_blank'
        rel='noopener noreferrer'>Products &amp; Company</a>
      <a className='legal__link' href='https://www.redbull.com/im/en_US' target='_blank'
        rel='noopener noreferrer'>Imprint</a>
      <a className='legal__link' href='https://www.redbull.com/bp/en_US' target='_blank'
        rel='noopener noreferrer'>Brand Protection</a>
      <a className='legal__link' href='https://www.redbull.com/fw/en_US' target='_blank'
        rel='noopener noreferrer'>Fraud Warning</a>
      <a className='legal__link' href='https://www.redbullcontentpool.com/' target='_blank'
        rel='noopener noreferrer'>Media</a>
      <a className='legal__link' href='http://energydrink-us.redbull.com/en/contact' target='_blank'
        rel='noopener noreferrer'>Contact Us</a>
      <a className='legal__link' href='http://jobs.redbull.com/us/en' target='_blank'
        rel='noopener noreferrer'>Jobs</a>
      <div className='legal__copyright'>	&copy; {new Date().getFullYear()} Red Bull</div>
    </nav>
  </div>
)

export const Color = (props) => {
  const colors = [
    { name: 'white', hex: '#ffffff' },
    { name: 'blue', hex: '#639df1' },
    { name: 'red', hex: '#b52541' },
    { name: 'green', hex: '#3cb878' },
    { name: 'purple', hex: '#8781bd' },
    { name: 'yellow', hex: '#ffd500' }
  ]

  return (
    <div className='colorPicker'>
      <label className='colorPickerLabel'>
        <span><img src={color} alt='Color' /></span>
        Color
      </label>

      <ColorSelector colors={colors} selected={props.value} onChange={props.onChange} />

      <button className='btn btn--ok' type='submit' onClick={props.onSubmit}>Ok</button>
    </div>
  )
}

export const Stickers = (props) => (
  <nav className='footerOptions'>
    <button className='btn btn--ok' onClick={props.onClick}>
      Add Stickers
    </button>
  </nav>
)

export const Resize = (props) => (
  <div className='sliderOption'>
    <div className='footerLabel'>
      <span><img src={resize} alt='Resize' /></span>
    </div>
    <Slider onChange={props.onChange} min={0.25} max={2} step={0.25} value={props.value} />
    <div className='action'>
      <button className='btn btn--ok' onClick={props.onSubmit} type='submit'>Ok</button>
    </div>
  </div>
)

export const Rotate = (props) => (
  <div className='sliderOption'>
    <div className='footerLabel'><span><img src={rotate} alt='Rotate' /></span>
    </div>
    <Slider onChange={props.onChange} min={0} max={360} step={5} value={props.value} />
    <div className='action'>
      <button className='btn btn--ok' type='submit' onClick={props.onSubmit}>Ok</button>
    </div>
  </div>
)

export const Options = (props) => (
  <nav className='footerOptions'>
    <button onClick={props.onRotateClick}>
      <span><img src={rotate} alt='logo' /></span>
      Rotate
    </button>
    <button onClick={props.onResizeClick}>
      <span><img src={resize} alt='resize' /></span>
      Resize
    </button>

    { props.showColor && (
      <button onClick={props.onColorClick}>
        <span><img src={color} alt='color' /></span>
        Color
      </button>
    )}
  </nav>
)

export const Text = (props) => (
  <nav className='footerOptions'>
    <button className='btn btn--ok' onClick={props.onClick}>
      Add Text
    </button>
  </nav>
)

export default (props) => (
  <footer className='footer'>
    {props.children}
  </footer>
)
