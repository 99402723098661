import React from 'react'
import DraggableComponent from 'react-draggable'

import closex from '../images/icon.close.svg'
import MediaContext from '../contexts/Media.js'

import '../scss/Blocks/blocks.draggable.scss'

// Padding inside draggable (15px) + border (5px)
export const PADDING = 15
export const PADDING_OFFSET = PADDING + 5
export const BASE_FONT_SIZE = 30
export const TEXT_MAX_WIDTH = 300
export const BASE_STICKER_SIZE = 128

class Draggable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handlePreventTouchmoveWhenPanning = this.handlePreventTouchmoveWhenPanning.bind(this)
  }

  select() {
    if (!this.props.focused) {
      this.props.context.focus(this.props.id)

      this.props.context.update(this.props.id, {
        hasPlaced: true
      })
    }
  }

  remove() {
    this.props.context.remove(this.props.id)
  }

  componentDidUpdate() {
    // console.log('Draggable.componentDidUpdate')
  }

  // -------- iOS page drag fixes START ----------
  onStart(e) {
    e.preventDefault()
  }

  handlePreventTouchmoveWhenPanning(e) {
    if (this.props.focused) {
      e.preventDefault()
    }
  }

  componentDidMount() {
    window.document.body.addEventListener(
      'touchmove',
      this.handlePreventTouchmoveWhenPanning,
      { passive: false }
    )
  }

  componentWillUnmount() {
    window.document.body.removeEventListener(
      'touchmove',
      this.handlePreventTouchmoveWhenPanning,
      { passive: false }
    )
  }
  // -------- iOS page drag fixes END ----------

  handleStopDragging(e, data) {
    this.props.context.update(this.props.id, {
      position: {
        x: data.x,
        y: data.y
      },
      hasPlaced: true
    })
  }

  render() {
    // console.log('Draggable.render', this.props)

    let classes = ['draggable']

    if (this.props.focused === true) {
      classes.push('focused')
    }

    classes = classes.join(' ')

    const styles = {
      transform: 'rotate(' + this.props.rotation + 'deg)',
      transformOrigin: 'center'
    }

    if (this.props.type === 'sticker') {
      styles.transform = styles.transform + 'scale(' + this.props.scale + ')'
    }

    if (this.props.type === 'text') {
      styles.fontSize = BASE_FONT_SIZE * this.props.scale
      styles.maxWidth = TEXT_MAX_WIDTH
      styles.padding = PADDING * this.props.ratio
    }

    return (
      <DraggableComponent
        handle='.drag-handle'
        disabled={!this.props.focused}
        defaultPosition={this.props.position}
        position={this.props.position}
        onStop={(e, data) => this.handleStopDragging(e, data)}
        cancel={this.props.focused ? '.content-editable' : ''}
      >
        <div className='drag-handle'>
          <div className={classes} style={styles} onClick={this.select.bind(this)}>
            { this.props.focused === true && (
              <button className='draggableRemove' onClick={this.remove.bind(this)}>
                <img src={closex} alt='Close'/>
              </button>
            )}

            <div className={
              'draggable--item' +
              (this.props.type === 'text' ? 'draggable--text draggable--item--variable-height' : '')
            }>
              {this.props.children}
            </div>
            {this.props.hasPlaced === false && this.props.focused &&
              <span className='draggable--label'>
                Tap to place {this.props.type === 'text' ? 'text' : 'sticker'}
              </span>
            }
          </div>
        </div>
      </DraggableComponent>
    )
  }
}

Draggable.defaultProps = {
  focused: false,
  position: { x: 0, y: 0 },
  scale: 1.0,
  ratio: 1,
  rotation: 0,
  hasPlaced: false
}

export default (props) => (
  <MediaContext.Consumer>
    {(context) => (
      <Draggable
        context={context}
        children={props.children}
        type={props.type}
        position={props.position}
        rotation={props.rotation}
        focused={props.focused}
        scale={props.scale}
        ratio={props.ratio || 1}
        hasPlaced={props.hasPlaced}
        id={props.id} />
    )}
  </MediaContext.Consumer>
)
