import React from 'react'

import CheerCardContext from '../../contexts/CheerCard.js'

import Hero from '../Hero.js'
import Text from '../Text.js'
import { Legal as LegalFooter, LegalFooter as Footer } from '../Footer.js'
import MediaSelect from '../MediaSelect.js'
import Prizes from '../Prizes.js'

import '../../scss/Blocks/blocks.startPage.scss'
import backgroundBottom from '../../images/asset.start-background.bottom.jpg'
import backgroundTop from '../../images/asset.hero-home.png'

const styles = {
  appBackground: {
    backgroundImage: `url(${backgroundBottom})`
  }
}

const Start = (props) => (
  <div className='startPage' style={styles.appBackground}>
    <Hero title='SCORE A DAY WITH THE TEAM.' image={backgroundTop}/>
    <Text>

    <h3>CONTEST CLOSED, WINNERS WILL BE ANNOUNCED SOON</h3>
    </Text>
    {/* <Text large >
      <p>
        Upload a personalized photo or video showing how you cheer for your team. Harness your creativity by adding stickers and custom text to make your cheer card stand out for a chance to win a New York Red Bulls player experience and other prizes.
      </p>
      <p>
        Judging will be based on creativity, relevance, and audience appeal. Runner up winners will be selected 7/22, 8/12, and 9/16. The Grand Prize winner will be selected 9/30
      </p>
    </Text>
    <Text>
      <p>For complete rules and judging criteria, click <a href='#rules' onClick={(e) => {
        e.preventDefault()
        props.context.setShowRules(true)
      }}>here.</a></p>
    </Text>
     */}
    {/* <MediaSelect onSelect={(selection) => {
      props.context.updateMediaSelection(selection)
      props.continue()
    }} /> */}

    <Text>
      <p>For a detailed prize description click <a href='#prizes' onClick={(e) => {
        e.preventDefault()
        props.context.setShowPrizes(true)
      }}>here.</a></p>
    </Text>
      <Prizes
        className='prizes'
        close={() => props.context.setShowPrizes(false)} active={props.context.showingPrizes} />
    <Text thin small>
      <p>
        NO PURC. NEC.  VOID WHERE PROHIBITED.  7/1/19–9/30/19.  Open to legal residents of NY, CT, and NJ living within 75 mi. of Red Bull Arena, 18+.  For method of entry, judging criteria, restrictions, prize information, and Official Rules:  www.redbull.com/supporters.  Sponsor: Red Bull New York, Inc., 600 Cape May St., Harrison, NJ 07029.
      </p>
    </Text>
    <Footer>
      <LegalFooter />
    </Footer>
  </div>
)

// Wrap the page component in the context
// so we can maintain state about the
// current cheer card.
export default ({ history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Start context={context} continue={() => {
        history.push('/stickers')
      }} />
    )}
  </CheerCardContext.Consumer>
)
