import React from 'react'

import { CheerCardProvider } from './contexts/CheerCard.js'
import Start from './components/pages/Start.js'
import Gallery from './components/pages/Gallery.js'
import Template from './components/pages/Templates.js'
import Stickers from './components/pages/Stickers.js'
import Text from './components/pages/Text.js'
import Share from './components/pages/Share.js'
import Review from './components/pages/Review.js'
import NotFound from './components/pages/NotFound.js'
import DesktopFrame from './components/DesktopFrame.js'
import Winners from './components/pages/Winners.js'

import { isBrowser } from 'react-device-detect'

import './scss/Blocks/blocks.rotateOverlay.scss'

import DeviceOrientation, { Orientation } from 'react-screen-orientation'


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

class App extends React.Component {
  componentDidMount() {
    // window.addEventListener('beforeunload', this.handleLeavePage.bind(this))
  }

  componentWillUnmount() {
    // window.removeEventListener('beforeunload', this.handleLeavePage.bind(this))
  }

  handleLeavePage(e) {
    const confirmationMessage = 'Warning, you will lose progress if you leave the page.'

    // Gecko, Trident, Chrome 34+
    e.returnValue = confirmationMessage

    // Gecko, WebKit, Chrome <34
    return confirmationMessage
  }

  render() {
    const routes = (
      <CheerCardProvider>
        <Switch>
          <Route exact={true} path='/' component={Winners} />
          {/*<Route exact={true} path='/template' component={Template} />*/}
          {/*<Route exact={true} path='/stickers' component={Stickers} />*/}
          <Route exact={true} path='/gallery' component={Gallery} />
          <Route exact={true} path='/gallery/:galleryId' render={(routeProps) => (
            <Gallery
              { ...routeProps }
              key={routeProps.match.params.galleryId}
              showingOverlay={true} />
          )} />
          {/*<Route exact={true} path='/text' component={Text} />*/}
          {/*<Route exact={true} path='/share' component={Share} />*/}
          {/*<Route exact={true} path='/review' component={Review} />*/}
          {/*<Route exact={true} path='/winners' component={Winners} />*/}
          <Route component={NotFound} />
        </Switch>
      </CheerCardProvider>
    )

    let appClassnames = ['App']

    if (isBrowser) {
      appClassnames.push('App--desktop')
    }

    return (
      <Router>
        <div className={appClassnames.join(' ')}>
          { isBrowser && (
            <DesktopFrame>
              {routes}
            </DesktopFrame>
          )}

          { !isBrowser && (
            <DeviceOrientation lockOrientation={'portrait'}>
              <Orientation orientation='landscape' alwaysRender={false}>
                <div className='rotateOverlay'>
                  <h1>Please rotate your device</h1>
                </div>
              </Orientation>
              <Orientation orientation='portrait' alwaysRender={true}>
                {routes}
              </Orientation>
            </DeviceOrientation>
          )}
        </div>
      </Router>
    )
  }
}

export default App
