import React from 'react'
import '../scss/Blocks/blocks.hero.scss'
import Rules from './pages/Rules'

import CheerCardContext from '../contexts/CheerCard.js'

import { Link } from 'react-router-dom'

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuActive: false
    }
  }

  render() {
    let menuClasses = ['menu']
    let menuToggleClasses = ['headerMenuToggle']

    const menuToggleActive = this.state.menuActive || this.props.context.showingRules

    if (menuToggleActive) {
      menuToggleClasses.push('active')
    }

    if (this.state.menuActive) {
      menuClasses.push('active')
    }

    const menuToggleClick = () => {
      if (this.props.context.showingRules) {
        this.props.context.setShowRules(false)
        return
      }

      this.setState({
        menuActive: !this.state.menuActive
      })
    }

    const rulesToggleClick = () => {
      this.props.context.setShowRules(true)
    }

    return (
      <div>
        <button onClick={menuToggleClick} className={menuToggleClasses.join(' ')}>
          <i className={menuToggleActive ? 'active' : ''}/>
        </button>

        <nav className={menuClasses.join(' ')}>
          <Link to='/' className='menuItem'>Home</Link>
          <Link to='/gallery' className='menuItem'>Gallery</Link>
          <button onClick={rulesToggleClick} className='menuItem'>Rules</button>
        </nav>
        <Rules active={this.props.context.showingRules} close={menuToggleClick} />
      </div>
    )
  }
}

export default () => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Menu context={context} />
    )}
  </CheerCardContext.Consumer>
)
