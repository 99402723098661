import React from 'react'

import ContentEditable from 'react-contenteditable'

class EditableText extends React.Component {
  render() {
    return (
      <ContentEditable
        disabled={this.props.disabled}
        style={this.props.style}
        html={this.props.text}
        className={['content-editable', this.props.className].join(' ')}
        onChange={this.props.onChange}
        tagName='p'
      />
    )
  }
}

EditableText.defaultProps = {
  disabled: false,
  style: {},
  className: '',
  text: '',
  onChange: () => {}
}

export default EditableText
