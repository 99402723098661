import React from 'react'

import CheerCardContext from '../../contexts/CheerCard.js'

import Header from '../Header.js'
import {
  Stickers as StickerFooter,
  Resize as ResizeFooter,
  Options as OptionsFooter,
  Rotate as RotateFooter,
  default as Footer
} from '../Footer.js'
import Media from '../Media.js'
import StickerSelect from '../StickerSelect.js'

export const MODE_PLACE = 'place'
export const MODE_ADD = 'add'
export const MODE_SHOW_ALL = 'show-all'
export const MODE_ROTATE = 'rotate'
export const MODE_SCALE = 'scale'

const DEFAULT_MODE = MODE_ADD

class Stickers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mode: DEFAULT_MODE
    }
    this.changeLocation = this.changeLocation.bind(this)
    this.media = React.createRef()

    if (!this.props.context.mediaSelection || !this.props.context.mediaSelection.file) {
      this.props.history.push('/')
    }
  }

  setMode(mode) {
    this.setState({
      mode: mode
    })
  }

  handleFocus() {
    this.setMode(MODE_PLACE)
  }

  handleUnfocus() {
    this.setMode(DEFAULT_MODE)
  }

  getModeHeading() {
    if (this.state.mode === MODE_PLACE) {
      return 'Place'
    }

    if (this.state.mode === MODE_SCALE) {
      return 'Resize'
    }

    if (this.state.mode === MODE_ROTATE) {
      return 'Rotate'
    }

    return 'Add'
  }

  componentDidMount() {
    this.props.context.unfocus()

    if (typeof this.media.current !== 'undefined') {
      this.props.context.setMediaResolution(this.media)
    }
  }

  renderFooter() {
    const selected = this.props.context.getSelected()

    if (this.state.mode === MODE_PLACE) {
      return (
        <OptionsFooter
          onRotateClick={() => {
            this.setMode(MODE_ROTATE)
          }}

          onResizeClick={() => {
            this.setMode(MODE_SCALE)
          }}
        />
      )
    }

    if (this.state.mode === MODE_ROTATE) {
      return (
        <RotateFooter
          value={selected.rotation || 0}

          onChange={(value) => {
            console.log('RotateFooter.onChange', value)
            this.props.context.updateSelected({ rotation: value })
          }}

          onSubmit={() => {
            this.setMode(MODE_PLACE)
          }}
        />
      )
    }

    if (this.state.mode === MODE_SCALE) {
      return (
        <ResizeFooter
          value={selected.scale || 1.0}

          onChange={(value) => {
            console.log('ResizeFooter.onChange', value)
            this.props.context.updateSelected({ scale: value })
          }}

          onSubmit={() => {
            this.setMode(MODE_PLACE)
          }}
        />
      )
    }

    return (
      <StickerFooter onClick={() => {
        this.setMode(MODE_SHOW_ALL)
      }} />
    )
  }

  handleAddSticker(sticker) {
    let index = this.props.context.addDraggable(sticker)

    this.props.context.focus(index)

    this.setMode(MODE_PLACE)
  }

  changeLocation() {
    this.setMode(MODE_ADD)
  }

  render() {
    return (
      <>
        <Header
          title='Stickers'
          links={{
            previous: this.state.mode === MODE_SHOW_ALL ? '/stickers' : '/',
            next: this.state.mode === MODE_SHOW_ALL ? '' : '/text'
          }}
          changeLocation={ this.changeLocation }
          showBack={ this.state.mode === MODE_SHOW_ALL }
          context={this.props.context}
          history={this.props.history}
        />

        { this.state.mode !== MODE_SHOW_ALL && (
          <Media
            innerRef={this.media}
            onItemFocus={(key) => this.handleFocus(key)}
            onItemUnfocus={() => this.handleUnfocus()}
          />
        )}

        { this.state.mode === MODE_SHOW_ALL && (
          <StickerSelect addSticker={(sticker) => this.handleAddSticker(sticker)} />
        )}

        { this.state.mode !== MODE_SHOW_ALL && (
          <Footer>
            {this.renderFooter()}
          </Footer>
        )}
      </>
    )
  }
}

// Wrap the page component in the context
// so we can maintain state about the
// current cheer card.
export default ({ history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Stickers context={context} history={history} />
    )}
  </CheerCardContext.Consumer>
)
