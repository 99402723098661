import React, { useState } from 'react'

import '../scss/Blocks/blocks.desktopLanding.scss'

import Background from '../images/asset.landing-background.jpg'
import Can from '../images/asset.redbullCan.desktopLanding.png'
import Phone from '../images/asset.handHoldingPhone.png'
import QR from '../images/asset.qrcode.jpg'

import DesktopFrameContext from '../contexts/DesktopFrame.js'

const DesktopFrame = (props) => {

  const [fixedScroll, setFixedScroll] = useState(false);

  return (
    <DesktopFrameContext.Provider
      value={{
        fixedScroll: fixedScroll,
        setFixedScroll: (value) => setFixedScroll(value),
      }}
    >
      <div className='desktopLanding'>
        <img src={Background} alt='New York Red Bulls Cheer Cards' className='desktopLandingBackground'/>

        <div className='desktopLandingCan'>
          <img src={Can} alt='Red Bull Can'/>
        </div>

        <div className='desktopLandingPhone'>
          <div className='desktopLandingPhoneAppContainer' style={{overflow: fixedScroll ? 'hidden' : ''}}>
            {props.children}
          </div>
          <div className='desktopLandingPhoneAppHand'>
            <img src={Phone} alt='Red Bull App'/>
          </div>
        </div>

{/*        <div className='desktopLandingQR'>
          <img src={QR} alt='Download the App' />
        </div>*/}
      </div>
    </DesktopFrameContext.Provider>
  )
}

export default DesktopFrame
