import React from 'react'
import logo from '../images/logo.nyrb.png'
import '../scss/Blocks/blocks.hero.scss'
import Menu from './Menu.js'

export default class Hero extends React.Component {
  render() {
    return (
      <div className='hero'>

        <img src={logo} className='heroBranding' alt='Red Bull' />

        <div className='heroImage'>
          <img src={this.props.image} alt='' />
        </div>
        <div className='heroShadow' />

        <h1>{this.props.title}</h1>

        <Menu hero />
      </div>
    )
  }
}
