
import React from 'react'

import '../scss/App.scss'
import '../scss/Blocks/blocks.gallery.scss'
import { ReactComponent as Loader } from '../images/loader.svg'

export default class GalleryGridItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    let img = new Image()
    img.onload = this.onLoad.bind(this)
    img.src = this.props.item.preview
  }

  onLoad() {
    this.setState({ isLoading: false })
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick({ ...this.props.item })
    }
  }

  render() {
    const { isLoading } = this.state
    const { item } = this.props

    const styles = {
      backgroundImage: `url(${item.preview})`
    }

    return (
      <div className={`galleryItem`} onClick={() => this.handleClick()} style={styles}>
        {isLoading && <Loader />}
        <span className='galleryItemText'>{item.user.name}</span>
      </div>
    )
  }
}
