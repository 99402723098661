import React from 'react'

import InputRange from 'react-input-range'

import 'react-input-range/lib/css/index.css'

class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value
    }
  }

  handleOnChange(value) {
    console.log('Slider.handleOnChange', value)
    value = parseFloat(value)

    if (this.props.onChange) {
      this.props.onChange(value)
    }

    this.setState({ value: value })
  }

  render() {
    return (
      <div className='slider'>
        <InputRange
          minValue={this.props.min}
          maxValue={this.props.max}
          formatLabel={() => {}}
          className='sliderTrack'
          value={this.state.value}
          onChange={(e) => this.handleOnChange(e)}
          step={this.props.step} />
      </div>
    )
  }
}

Slider.defaultProps = {
  value: 0,
  min: 0,
  max: 360,
  step: 1
}

export default Slider
