import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import GalleryItemOverlay from './GalleryItemOverlay.js'

import '../scss/App.scss'
import '../scss/Blocks/blocks.gallery.scss'

import { fetchGallery } from '../helpers/api.js'
import { chunk } from '../helpers/arrays.js'
import { ReactComponent as Loader } from '../images/loader.svg'
import GalleryGrid, {
  LAYOUT_INLINE,
  LAYOUT_LARGE_RIGHT,
  LAYOUT_LARGE_LEFT
} from './GalleryGrid'

const initialState = {
  items: [],
  nextPageHandler: false
}

class Gallery extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }

    if (props.galleryId) {
      window.scrollTo(0, 0)
    }
  }

  galleryResponseHandler(payload) {
    let { results, next } = payload

    let galleryItems = [ ...this.state.items ]
    let newGalleryItems = galleryItems.concat([ ...results ])

    this.setState({
      items: newGalleryItems,
      nextPageHandler: next
    })
  }

  loadNextPage() {
    const galleryPaginator = this.state.nextPageHandler.bind ? this.state.nextPageHandler.bind(this) : fetchGallery()
    galleryPaginator().then(this.galleryResponseHandler.bind(this))
  }

  componentDidMount() {
    this.loadNextPage()
  }

  componenWillUnmount() {
    this.setState({
      ...initialState
    })
  }

  render() {
    let showLeft = false
    const { items, isLoading } = this.state
    const styles = {
      gallery: {
        position: 'relative'
      }
    }

    let classNames = ['gallery']

    if (this.props.galleryId) {
      classNames.push('gallery--single')
    }

    return (
      <div className={classNames.join(' ')} style={styles.gallery}>
        { this.props.galleryId && (
          <GalleryItemOverlay itemId={this.props.galleryId} onCloseOverlay={() => this.props.onCloseOverlay()} />
        )}

        <InfiniteScroll
          dataLength={this.state.items.length} // This is important field to render the next data
          next={() => this.loadNextPage()}
          hasMore={this.state.nextPageHandler !== false}
          loader={
            <div className='stickerSelect--loadingAll'>
              <Loader />
            </div>
          }
        >
          {chunk([...items], 3).map((chunked, index) => {
            let layout = LAYOUT_INLINE
            let adjustedIndex = index + 1

            if (adjustedIndex % 2 !== 0) {
              layout = (showLeft ? LAYOUT_LARGE_LEFT : LAYOUT_LARGE_RIGHT)
              showLeft = !showLeft
            }

            return (
              <GalleryGrid
                layout={layout}
                key={index}
                items={chunked}
                onItemClick={this.props.onItemClick} />
            )
          })}
        </InfiniteScroll>
      </div>
    )
  }
}

Gallery.defaultProps = {
  galleryId: false
}

export default Gallery
