import React from 'react'
import '../scss/Blocks/blocks.text.scss'

class Text extends React.Component {
  render() {
    let classNames = ['text']

    if (this.props.thin) {
      classNames.push('text--thin')
    }

    if (this.props.small) {
      classNames.push('text--small')
    }

    if (this.props.large) {
      classNames.push('text--large')
    }

    return (
      <section className={classNames.join(' ')}>
        {this.props.children}
      </section>
    )
  }
}


Text.defaultProps = {
  thin: false,
  small: false,
  large: false
}

export default Text
