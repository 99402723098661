import {
  PADDING_OFFSET,
  BASE_FONT_SIZE,
  TEXT_MAX_WIDTH,
  BASE_STICKER_SIZE
} from '../components/Draggable.js'

export const PIXEL_RATIO = (() => {
  const ctx = document.createElement('canvas').getContext('2d')
  const dpr = window.devicePixelRatio || 1
  const bsr = ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio || 1

  return dpr / bsr
})()

const createHiDPICanvas = (w, h, ratio) => {
  let canvas = document.createElement('canvas')

  // console.log('createHiDPICanvas params', w, h, ratio)

  canvas.width = w * ratio
  canvas.height = h * ratio
  canvas.style.width = w + 'px'
  canvas.style.height = h + 'px'
  canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0)

  // console.log('createHiDPICanvas canvas', canvas)

  return canvas
}

const wrapText = (context, text, position, maxWidth, lineHeight) => {
  let words = text.split(' ')
  let line = ''
  let lineCount = 0
  let i = 0
  let test = false
  let { x, y } = position

  for (i = 0; i < words.length; i = i + 1) {
    test = words[i]
    let metrics = context.measureText(test)

    while (metrics.width > maxWidth) {
      // Determine how much of the word will fit
      test = test.substring(0, test.length - 1)
      metrics = context.measureText(test)
    }

    if (words[i] !== test) {
      words.splice(i + 1, 0, words[i].substr(test.length))
      words[i] = test
    }

    test = line + words[i] + ' '
    metrics = context.measureText(test)

    if (metrics.width > maxWidth && i > 0) {
      context.fillText(line, x, y)
      line = words[i] + ' '
      y = y + lineHeight
      lineCount = lineCount + 1

      continue
    }

    line = test
  }

  context.fillText(line, x, y)
}

/**
 * Given some options we'll create a temporary canvas and
 * render some images and text to it based on the information
 * about positioning, scale, rotation etc.
 *
 * @param  {object} options Information about the canvas
 * @return {Promise} A promise that resolves when it's finished
 */
export const renderToCanvas = (options) => {
  return new Promise((resolve) => {
    let {
      background,
      objects,
      dimensions
    } = options

    console.log('renderToCanvas.options', options)

    const {
      width,
      height
    } = dimensions

    let canvas = createHiDPICanvas(width, height, PIXEL_RATIO)

    const {
      file,
      dataUrl
    } = background

    let ctx = canvas.getContext('2d')

    ctx.save()

    let backgroundImagePromise = new Promise((resolveBackgroundImage, rejectBackgroundImage) => {
      console.log('renderToCanvas.file', file, dataUrl)

      if (file.type.match(/video\/*/gi)) {
        console.log('Create video element')
        let videoElement = document.createElement('video')
        videoElement.setAttribute('muted', true)
        videoElement.setAttribute('autoplay', true)
        videoElement.setAttribute('playsinline', true)

        console.log('Add video listener')
        let loaded = false
        videoElement.addEventListener('loadeddata', () => {
          if (loaded) {
            return
          }

          loaded = true
          let canvasRatio = canvas.width / canvas.height
          let cropHeight = videoElement.videoHeight
          let cropWidth = videoElement.videoHeight * canvasRatio

          if (videoElement.videoHeight < videoElement.videoWidth) {
            canvasRatio = canvas.height / canvas.width
            cropWidth = videoElement.videoWidth
            cropHeight = cropWidth * canvasRatio
          }

          const xStart = (videoElement.videoWidth / 2) - (cropWidth / 2)
          const yStart = (videoElement.videoHeight / 2) - (cropHeight / 2)

          ctx.drawImage(
            videoElement,
            xStart,
            yStart,
            cropWidth,
            cropHeight,
            0,
            0,
            width,
            height
          )

          console.log('renderToCanvas Video loaded')

          resolveBackgroundImage()
        })

        console.log('Add video on error')
        videoElement.onerror = (e) => {
          console.log('renderToCanvas Video loading error')
          rejectBackgroundImage(e)
        }

        videoElement.setAttribute('crossOrigin', 'anonymous')

        console.log('Add video src')
        videoElement.src = dataUrl
        return
      }

      let image = new Image()

      image.onload = () => {
        let canvasRatio = canvas.width / canvas.height
        let cropHeight = image.height
        let cropWidth = image.height * canvasRatio

        if (image.height < image.width) {
          canvasRatio = canvas.height / canvas.width
          cropWidth = image.width
          cropHeight = cropWidth * canvasRatio
        }

        const xStart = (image.width / 2) - (cropWidth / 2)
        const yStart = (image.height / 2) - (cropHeight / 2)

        ctx.drawImage(
          image,
          xStart,
          yStart,
          cropWidth,
          cropHeight,
          0,
          0,
          width,
          height
        )

        console.log('renderToCanvas Image loaded')

        resolveBackgroundImage()
      }

      image.onerror = (e) => {
        console.log('renderToCanvas Image error')
        rejectBackgroundImage(e)
      }

      image.setAttribute('crossOrigin', 'anonymous')

      image.src = dataUrl
    })

    let objectPromises = []

    backgroundImagePromise.then(() => {
      // Go through each object and render it to
      // the canvas through our 2d context.
      for (let i in objects) {
        if (objects[i]) {
          objectPromises.push(new Promise((resolveObject, rejectObject) => {
            let object = objects[i]

            const {
              x,
              y
            } = object.position

            // Handle text
            if (object.type === 'text') {
              const fontSize = BASE_FONT_SIZE * object.scale

              ctx.fillStyle = object.color
              ctx.textBaseline = 'top'
              ctx.font = fontSize + 'px "Futura Bold", "Helvetica", Arial'

              const baseLineHeight = fontSize * 1.4
              const lineHeightOffset = (baseLineHeight - (fontSize)) / 2

              const actualX = x + PADDING_OFFSET
              // Adjust using the offset in line-height as the fillText doesn't account for it.
              const actualY = y + PADDING_OFFSET + lineHeightOffset

              const actualWidth = TEXT_MAX_WIDTH * 0.85
              const actualHeight = baseLineHeight

              const halfWidth = actualWidth / 2
              const halfheight = actualHeight / 2

              // Move top-left corner to center.
              ctx.translate(actualX + halfWidth, actualY + halfheight)

              // Rotate it
              ctx.rotate(object.rotation * Math.PI / 180)

              // Put it back
              ctx.translate(-(actualX + halfWidth), -(actualY + halfheight))

              wrapText(
                ctx,
                object.value,
                { x: actualX, y: actualY },
                // We need to adjust this manually because it renders with different kerning.
                actualWidth,
                baseLineHeight
              )

              resolveObject()
              return
            }

            let image = new Image()

            image.onload = () => {
              const actualX = x + PADDING_OFFSET
              const actualY = y + PADDING_OFFSET

              const actualWidth = BASE_STICKER_SIZE * object.scale
              const actualHeight = BASE_STICKER_SIZE * object.scale

              const halfWidth = actualWidth / 2
              const halfheight = actualHeight / 2

              // Move top-left corner to center.
              ctx.translate(actualX + halfWidth, actualY + halfheight)

              // Rotate it
              ctx.rotate(object.rotation * Math.PI / 180)

              // Put it back
              ctx.translate(-(actualX + halfWidth), -(actualY + halfheight))

              if (object.is_sprite === 1) {
                let frameX = 0
                let frameY = 0
                let frameWidth = object.frame_size
                let frameHeight = object.frame_size

                ctx.drawImage(
                  image,
                  frameX,
                  frameY,
                  frameWidth,
                  frameHeight,
                  actualX,
                  actualY,
                  actualWidth,
                  actualHeight
                )
              } else {
                ctx.drawImage(
                  image,
                  actualX,
                  actualY,
                  actualWidth,
                  actualHeight
                )
              }

              resolveObject()
            }

            image.onerror = (e) => {
              console.error('Error loading image', e)
              rejectObject(e)
            }

            image.setAttribute('crossOrigin', 'anonymous')
            image.src = object.value
          }))
        }
      }

      Promise.all(objectPromises).then(() => {
        resolve({
          canvas,
          dataUrl: canvas.toDataURL()
        })
      }).catch((err) => {
        console.error(err)
      })
    })
  })
}

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  let byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  let arrayBuffer = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i = i + 1) {
    ia[i] = byteString.charCodeAt(i)
  }

  let dataView = new DataView(arrayBuffer)
  let blob = new Blob([dataView], { type: mimeString })

  return blob
}
