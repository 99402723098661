import React from 'react'


import '../scss/App.scss'
import '../scss/Blocks/blocks.gallery.scss'

import GalleryGridItem from './GalleryGridItem'
export const LAYOUT_INLINE = 0
export const LAYOUT_LARGE_RIGHT = 1
export const LAYOUT_LARGE_LEFT = 2

export default class GalleryGrid extends React.Component {
  render() {
    let classNames = ['galleryGrid']
    const layout = this.props.layout || LAYOUT_INLINE

    if (layout === LAYOUT_INLINE) {
      classNames.push('galleryGrid--inline')
    }

    if (layout === LAYOUT_LARGE_RIGHT) {
      classNames.push('galleryGrid--large-right')
    }

    if (layout === LAYOUT_LARGE_LEFT) {
      classNames.push('galleryGrid--large-left')
    }

    return (
      <div className={classNames.join(' ')}>
        { this.props.items.map((item, itemIndex) => {
          return <GalleryGridItem item={item} key={itemIndex} onClick={this.props.onItemClick} />
        })}
      </div>
    )
  }
}


