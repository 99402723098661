/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Sprites from './Sprites.js'

import { ReactComponent as Loader } from '../images/loader.svg'

export default function LoadingImage(props) {

  const [loaded, setLoaded] = useState(false)
  const { sticker } = props
  let onLoad = () => {
    setLoaded(true)
  }
  useEffect(() => {
    return () => {
      onLoad = () => {}
    }
  })

  return (
    <>
      <div className={`stickerSelect--loadingSingle ${loaded ? 'hidden' : ''}`}>
        <Loader />
      </div>
      {sticker.is_sprite ?
        <Sprites
          sticker={sticker}
          onLoad={() => onLoad()}
          className={`App-logo ${!loaded ? 'hidden' : ''}`}
          alt='logo'
        />
        :
        <img
          src={sticker.url}
          onLoad={onLoad}
          className={`App-logo ${!loaded ? 'hidden' : ''}`}
          alt='logo'
        />
      }
    </>
  )
}

LoadingImage.defaultProps = {
  sticker: {}
}

