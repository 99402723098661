import React from 'react'

import EditableText from './EditableText.js'
import Draggable, { BASE_STICKER_SIZE, BASE_FONT_SIZE } from './Draggable.js'
import MediaBackground from './MediaBackground.js'
import Sprites from './Sprites.js'

import CheerCardContext from '../contexts/CheerCard.js'
import MediaContext from '../contexts/Media.js'

import '../scss/Blocks/blocks.media.scss'

class Media extends React.Component {
  focus(key) {
    if (this.props.context.reviewPage === true) {
      return
    }

    this.props.context.focus(key)
    this.props.onFocus(key)
  }

  onClick(e) {
    if (this.props.context.reviewPage === true) {
      return
    }

    if (e && e.target.className.match('draggableContents')) {
      return
    }

    this.props.context.unfocus()
    this.props.onUnfocus()
  }

  remove(key) {
    if (this.props.context.reviewPage === true) {
      return
    }

    this.props.context.unfocus(null)
    this.props.context.removeDraggable(key + 1)
  }

  update(key, data) {
    if (this.props.context.reviewPage === true) {
      return
    }

    // console.log('Media.update', key, data)
    this.props.context.updateDraggable(key, { ...data })
  }

  handleContentEditable(e) {
    if (this.props.context.reviewPage === true) {
      return
    }

    this.props.context.updateSelected({
      value: e.target.value
    })
  }

  render() {
    // console.log('Media.render')
    const selectedKey = this.props.context.selected
    const draggables = this.props.draggables.map((draggableObject, index) => {
      let selected = index === selectedKey

      // Can never be selected on the review pages.
      if (this.props.context.reviewPage === true) {
        selected = false
      }

      let scaleOffset = 0
      let objectSize = BASE_STICKER_SIZE * draggableObject.scale

      if (draggableObject.type === 'text') {
        objectSize = BASE_FONT_SIZE * draggableObject.scale
      }

      scaleOffset = (objectSize - (objectSize * this.props.ratio)) / draggableObject.scale

      let position = {
        x: (draggableObject.position.x - scaleOffset) * this.props.ratio,
        y: (draggableObject.position.y - scaleOffset) * this.props.ratio
      }

      // console.log(
      //   'Scaling calculations',
      //   this.props.ratio,
      //   objectSize,
      //   objectSize * this.props.ratio,
      //   scaleOffset,
      //   draggableObject.position.x,
      //   draggableObject.position.y,
      //   position.x,
      //   position.y
      // )

      return (
        <Draggable
          key={index}
          id={index}
          type={draggableObject.type}
          position={position}
          rotation={draggableObject.rotation}
          ratio={this.props.ratio}
          scale={draggableObject.scale * this.props.ratio}
          focused={selected}
          hasPlaced={draggableObject.hasPlaced}
        >
          { draggableObject.type === 'sticker' && (
            draggableObject.is_sprite ?
              <Sprites
                sticker={draggableObject}
                className='draggableSticker draggableContents'
              />
              :
              <img
                src={draggableObject.value}
                className='draggableSticker draggableContents'
                alt={draggableObject.name} />
          )}

          { draggableObject.type === 'text' && (
            <EditableText
              key={'draggable-text-' + index}
              className='draggableContents'
              style={{ color: draggableObject.color || '#fff' }}
              text={draggableObject.value}
              disabled={!selected}
              onChange={(e) => this.handleContentEditable(e)}
            />
          )}
        </ Draggable>
      )
    })

    return (
      <div className='media' ref={this.props.innerRef} onClick={(e) => this.onClick(e)}>
        { this.props.mediaSelection && this.props.mediaSelection.file && (
          <MediaBackground
            type={this.props.mediaSelection.file.type}
            dataUrl={this.props.mediaSelection.dataUrl}
          />
        )}

        <div className='mediaEdits'>
          <MediaContext.Provider
            value={{
              remove: this.remove.bind(this),
              focus: this.focus.bind(this),
              update: this.update.bind(this)
            }}
          >
            {draggables}
          </MediaContext.Provider>
        </div>
      </div>
    )
  }
}

Media.defaultProps = {
  ratio: 1,
  innerRef: null,
  context: null,
  draggables: [],
  mediaSelection: null,
  onFocus: () => {},
  onUnfocus: () => {}
}

export default (props) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Media
        ratio={props.ratio || 1}
        innerRef={props.innerRef}
        context={context}
        draggables={props.draggables || context.draggables}
        mediaSelection={props.mediaSelection || context.mediaSelection}
        onFocus={props.onItemFocus}
        onUnfocus={props.onItemUnfocus} />
    )}
  </CheerCardContext.Consumer>
)
