import React, { useEffect } from 'react'

import '../scss/Blocks/blocks.modal.scss'

export default function Prizes(props) {
  useEffect(() => {
    if (props.active) {
      window.scroll({ top: 0, behaviour: 'smooth' })
    }
  }, [props.active])

  return (
    <div id='toolbox' className={`${ props.className } modal ${props.active ? 'active' : ''}`}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <button style={{}} onClick={props.close}>X</button>
      </div>
      <p>
        The Grand Prize winner (selected 9/30) will experience what it’s like to be a Red Bulls player - an all-access soccer experience most fans dream of. On day one, they will head to the RBNY practice facility where they’ll join drills, have lunch with the team, sit through media interviews, and watch team scrimmages up close. On game day, they’ll receive a personalized kit before heading down to the field to watch the team warmup. After warm ups, they will be able to watch the game from the coveted manager’s box seats, right behind the player benches. Twelve runner up winners (selected 7/22, 8/12, & 9/16) will enjoy four tickets to a New York Red Bulls match, Red Bulls merchandise, and their cheer cards will displayed on the jumbotron at the Red Bull Arena.
      </p>
    </div>
  )
}
