import React from 'react'
import logo from '../images/logo.nyrb.png'
import arrow from '../images/icon.arrow.svg'
import '../scss/App.scss'
import '../scss/Blocks/blocks.header.scss'
import Menu from './Menu.js'

import { Link } from 'react-router-dom'

export default class Header extends React.Component {
  render() {
    return (
      <header className='header'>
        <div className='headerBranding'>
          <a
            onClick={(e) => {
              e.preventDefault()
              this.props.context.reset()
              this.props.history.push('/')
            }}
            className='startNew'>
            <img src={logo} className='App-logo' alt='logo' />
          </a>
        </div>

        { this.props.title && (
          <nav className='headerNav'>
            { this.props.links && this.props.links.previous && (
              <Link
                to={this.props.links.previous}
                className='headerNavItem headerNavItem--previous'
                onClick={this.props.changeLocation}
              >
                <img src={arrow} alt='Previous' />
                <span>{this.props.showBack ? 'Close' : 'Back'}</span>
              </Link>
            )}
            <h1>{this.props.title}</h1>
            { this.props.links && this.props.links.next && (
              <Link to={this.props.links.next} className='headerNavItem headerNavItem--next'>
                <span>Next</span>
                <img src={arrow} alt='Next' />
              </Link>
            )}
          </nav>
        )}

        <Menu />
      </header>
    )
  }
}
