import React from 'react'

import CheerCardContext from '../../contexts/CheerCard.js'

import Header from '../Header.js'
import { Legal as LegalFooter, LegalFooter as Footer } from '../Footer.js'
import ShareBlock from '../Share.js'

import { saveUserSubmission } from '../../helpers/api.js'
import { renderToCanvas, dataURItoBlob } from '../../helpers/canvas.js'

const initialState = {
  preview: null,
  downloadUrl: false
}

class Share extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...initialState
    }

    if (!this.props.context.mediaSelection || !this.props.context.mediaSelection.file) {
      this.props.history.push('/')
    }
  }

  componentDidMount() {
    if (!this.props.context.mediaSelection) {
      return
    }

    this.props.context.reviewPage = true

    const objectData = {
      background: {
        file: {
          type: this.props.context.mediaSelection.file.type
        },
        dataUrl: this.props.context.mediaSelection.dataUrl
      },
      objects: { ...this.props.context.draggables },
      dimensions: this.props.context.dimensions
    }

    renderToCanvas(objectData).then((data) => {
      const blobData = dataURItoBlob(data.dataUrl)

      saveUserSubmission(objectData, blobData).then((response) => {
        const responseData = response.data
        const { download } = responseData

        this.setState({
          preview: data.dataUrl,
          downloadUrl: download
        })
      })
    }).catch(error => {
      console.error(error)
    })
  }

  componentWillUnmount() {
    this.props.context.reviewPage = false
  }

  render() {
    return (
      <>
        <Header
          title='Share'
          links={{ previous: '/text', next: false }}
          context={this.props.context}
          history={this.props.history}
        />

        <ShareBlock
          downloadUrl={this.state.downloadUrl}
          preview={this.state.preview}
          context={this.props.context}
          history={this.props.history} />

        <Footer>
          <LegalFooter />
        </Footer>
      </>
    )
  }
}

// Wrap the page component in the context
// so we can maintain state about the
// current cheer card.
export default ({ history }) => (
  <CheerCardContext.Consumer>
    {(context) => (
      <Share context={context} history={history} />
    )}
  </CheerCardContext.Consumer>
)
