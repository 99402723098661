import React from 'react'

import { fetchStickers } from '../helpers/api.js'
import { DraggableObject } from '../objects/Objects.js'
import LoadingImage from './LoadingImage.js'
import { ReactComponent as Loader } from '../images/loader.svg'

import '../scss/Blocks/blocks.stickerSelect.scss'

const initialState = {
  stickers: [],
  isLoading: true
}
const radioStyle = { opacity: 0 }

class StickerSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  componentDidMount() {
    fetchStickers().then((stickers) => {
      this.setState({
        stickers: stickers,
        isLoading: false
      })
    })
  }

  handleClick(sticker) {
    const stickerObject = new DraggableObject('sticker', sticker.url, {
      x: 10,
      y: 10
    }, 1, 0, sticker.is_sprite, sticker.steps, sticker.frame_size, sticker.fps)
    this.props.addSticker(stickerObject)
  }

  render() {
    const { isLoading, stickers } = this.state
    return (
      <>
        {isLoading &&
          <div className='stickerSelect--loadingAll'>
            <Loader />
          </div>
        }
        {!isLoading &&
        <div className='stickerSelect'>
          {stickers.map((sticker, index) => (
            <div className='stickerSelectItem' key={index} onClick={() => this.handleClick(sticker)}>
              <input type='radio' name='sticker' id={'sticker-' + index} style={radioStyle} />
              <label htmlFor={'sticker-' + index} className='stickerItemLabel'>
                {isLoading &&
                  <div className='stickerSelect stickerSelect--loadingSingle'>
                    <Loader />
                  </div>
                }
                <LoadingImage sticker={sticker} />
              </label>
            </div>
          ))}
        </div>}
      </>
    )
  }
}

export default StickerSelect
