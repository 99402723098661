/* eslint-disable camelcase */
import axios from 'axios'

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export const fetchUser = () => {
  return Promise.resolve({
    id: 1,
    redbull_id: '5ced3ff657dd6405f4162ff8',
    display_name: 'craig',
    email: 'craig@analogrepublic.com',
    first_name: 'Craig',
    last_name: 'Childs',
    access_token: '94cccfc6fd3d0ea01cb7550aabafa46a',
    refresh_token: '6c0f3451b8b7aefb49e47fdf770bcd20'
  })
}

export const fetchStickers = () => {
  return client.get('/stickers')
    .then(response => response.data)
}

export const fetchTemplates = () => {
  return client.get('/templates')
    .then(response => response.data)
}

export const paginate = (endpointCallback) => {
  return () => {
    return new Promise((resolve, reject) => {
      endpointCallback().then((response) => {
        const responseData = response.data

        const {
          data,
          prev_page_url,
          next_page_url,
          current_page,
          last_page
        } = responseData

        let payload = {
          next: false,
          previous: false,
          current: current_page,
          last: last_page,
          results: data
        }

        if (prev_page_url) {
          payload.previous = paginate(() => {
            return axios.get(prev_page_url)
          })
        }

        if (next_page_url) {
          payload.next = paginate(() => {
            return axios.get(next_page_url)
          })
        }

        resolve(payload)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export const fetchGallery = () => {
  return paginate(() => {
    return client.get('/gallery')
  })
}

export const fetchGalleryItem = (id) => {
  const params = getParams(window.location)

  let url = `/gallery/${id}`

  if (params['token']) {
    url = `/gallery/${id}?token=${params['token']}`
  }

  return client.get(url)
}

export const saveUserSubmission = (data, file) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  let formData = new FormData()

  formData.append('preview', file)
  formData.append('data', JSON.stringify(data))

  return client.post('/submit', formData, { headers })
}

export const uploadFile = (file) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  let formData = new FormData()

  formData.append('file', file)

  return client.post('/upload', formData, { headers })
}

const getParams = (url) => {
  let params = {}
  let parser = document.createElement('a')
  parser.href = url

  let query = parser.search.substring(1)
  let vars = query.split('&')

  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    params[pair[0]] = decodeURIComponent(pair[1])
  }

  return params
};
